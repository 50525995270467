'use strict'

document.addEventListener('DOMContentLoaded', function(e){
    
});



//MÉTODOS

$("#form-search-activities").submit(function (event) {
    loadingGifFunction();
});